import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = [ 'brand', 'model', 'fuel', 'select' ]
  static values = {
    url: String,
    param: String
  }

  change(event) {
    let params = new URLSearchParams()
    params.append('brand', this.brandTarget.selectedOptions[0].value)
    params.append('model', this.modelTarget.selectedOptions[0].value)
    params.append('fuel', event.target.selectedOptions[0].value)
    params.append('target', this.selectTarget.id)
    let url = `/home/get_cars/?${params}`;

    get(url, {
      responseKind: 'turbo-stream'
    })
  }

  change_brand(event) {
    let params = new URLSearchParams()
    params.append('brand', event.target.selectedOptions[0].value)
    let url = `/home/get_cars_model/?${params}`;

    get(url, {
      responseKind: 'turbo-stream'
    })
  }

  change_model(event) {
    let params = new URLSearchParams()
    params.append('model', event.target.selectedOptions[0].value)
    params.append('brand', this.brandTarget.selectedOptions[0].value)
    let url = `/home/get_cars_fuel/?${params}`;

    get(url, {
      responseKind: 'turbo-stream'
    })
  }
}